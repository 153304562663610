import styled from "@emotion/styled";

const Figcaption = styled.figcaption`
  color: #777;
  font-size: 14px;
  text-align: center;
  width: 100%;
  font-style: italic;
`;

export default Figcaption; 